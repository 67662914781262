import { isProd } from "../environment";
import { CURRENT_ENVIRONMENT } from "./common";

export * from "./common";
export * from "./amplify";

export const DOCUMENTS_HOST =
  process.env["DOCUMENTS_HOST"] ?? `https://documentstore.${CURRENT_ENVIRONMENT}.documents.stedi.com`;
export const INTEGRATIONS_HOST =
  process.env["INTEGRATIONS_HOST"] ??
  (window.location.host === "portal.stedi.com"
    ? "https://portal.stedi.com/terminal/api/integrations"
    : `https://api.${CURRENT_ENVIRONMENT}.integrations.stedi.com`);
export const LABELS_HOST =
  process.env["LABELS_HOST"] ??
  (window.location.host === "portal.stedi.com"
    ? "https://portal.stedi.com/terminal/api/transactions-labels"
    : `https://labels.${CURRENT_ENVIRONMENT}.transactions.stedi.com/v0`);
export const LEDGER_HOST =
  process.env["LEDGER_HOST"] ??
  (window.location.host === "portal.stedi.com"
    ? "https://portal.stedi.com/terminal/api/transactions-ledger"
    : `https://ledger.${CURRENT_ENVIRONMENT}.transactions.stedi.com`);
export const LEDGER_SEARCH_HOST =
  process.env["LEDGER_SEARCH_HOST"] ??
  (window.location.host === "portal.stedi.com"
    ? "https://portal.stedi.com/terminal/api/transactions-search"
    : `https://search.${CURRENT_ENVIRONMENT}.transactions.stedi.com`);
export const SUBSCRIPTIONS_HOST =
  process.env["SUBSCRIPTIONS_HOST"] ?? `wss://ws.${CURRENT_ENVIRONMENT}.terminal.stedi.com`;
export const FLAGS_HOST = process.env["FLAGS_HOST"] ?? `https://flags.${CURRENT_ENVIRONMENT}.terminal.stedi.com`;
export const LOGOS_HOST = process.env["LOGOS_HOST"] ?? `https://logos.${CURRENT_ENVIRONMENT}.terminal.stedi.com`;

type ServiceName =
  | "workflows"
  | "functions"
  | "schemas"
  | "parameters"
  | "mappings"
  | "events"
  | "transports"
  | "edi-core"
  | "edi-data"
  | "formation"
  | "ledger"
  | "guides"
  | "apikeys.us";

export const buildServiceUrl = (service: ServiceName, version: string, isProduction: boolean): string => {
  const subdomain = isProduction ? service : `${service}.preproduction`;
  return `https://${subdomain}.stedi.com/${version}`;
};

// Used for Formation labels
const buildRoot = (host: string): string => {
  return host.split("https://")[1];
};

export const WORKFLOWS_HOST = process.env["WORKFLOWS_HOST"] ?? buildServiceUrl("workflows", "20210601", isProd());

// Transformers team
export const SCHEMAS_HOST = process.env["SCHEMAS_HOST"] ?? buildServiceUrl("schemas", "2021-06-01", isProd());
export const SCHEMAS_ROOT = buildRoot(SCHEMAS_HOST);

export const PARAMETERS_HOST = process.env["PARAMETERS_HOST"] ?? buildServiceUrl("parameters", "2021-06-01", isProd());
export const PARAMETERS_ROOT = buildRoot(PARAMETERS_HOST);

export const FUNCTIONS_HOST = process.env["FUNCTIONS_HOST"] ?? buildServiceUrl("functions", "2021-06-01", isProd());
export const FUNCTIONS_ROOT = buildRoot(FUNCTIONS_HOST);

export const MAPPINGS_UI_HOST = isProd()
  ? "https://www.stedi.com/app/mappings"
  : "https://preview.stedi.com/app/mappings";

export const DASHBOARD_UI_HOST = isProd() ? "https://www.stedi.com/app" : "https://preview.stedi.com/app";

// Events team
export const EVENTS_ROOT = `${CURRENT_ENVIRONMENT}.events.stedi.com`;
export const EVENTS_HOST = process.env["EVENTS_HOST"] ?? `https://${CURRENT_ENVIRONMENT}.events.stedi.com/20210601`;

// Transports team
export const TRANSPORTS_HOST = process.env["TRANSPORTS_HOST"] ?? buildServiceUrl("transports", "2021-06-15", isProd());

// EDI team
export const EDICORE_HOST = process.env["EDICORE_HOST"] ?? buildServiceUrl("edi-core", "2021-06-05", isProd());
export const EDIGUIDES_HOST = process.env["EDIGUIDES_HOST"] ?? buildServiceUrl("guides", "2021-06-05", isProd());

// SaaSCore Team
export const API_KEYS_HOST = process.env["API_KEYS_HOST"] ?? buildServiceUrl("apikeys.us", "2022-01-19", isProd());
export const MEMBERS_HOST = process.env["MEMBERS_HOST"] ?? `https://members.${CURRENT_ENVIRONMENT}.saas.stedi.com`;
export const NOTIFICATIONS_HOST =
  process.env["NOTIFICATIONS_HOST"] ?? `https://notifications.${CURRENT_ENVIRONMENT}.saas.stedi.com`;
export const TENANT_HOST = process.env["TENANT_HOST"] ?? `https://accounts.${CURRENT_ENVIRONMENT}.saas.stedi.com`;
export const TOKENS_HOST = process.env["TOKENS_HOST"] ?? `https://tokens.${CURRENT_ENVIRONMENT}.saas.stedi.com`;
export const USERS_HOST = process.env["USERS_HOST"] ?? `https://users.${CURRENT_ENVIRONMENT}.saas.stedi.com`;
export const PARTNERS_HOST = process.env["PARTNERS_HOST"] ?? `https://partners.${CURRENT_ENVIRONMENT}.saas.stedi.com`;

// Provisioning team
export const FORMATION_HOST = process.env["FORMATION_HOST"] ?? buildServiceUrl("formation", "2021-08-10", isProd());
export const FORMATION_ROOT = buildRoot(FORMATION_HOST);

export const CLOUD_EVENTS_HOST =
  process.env["CLOUD_EVENTS_HOST"] ?? buildServiceUrl("events.cloud" as ServiceName, "2021-11-16", isProd());
export const CLOUD_EVENTS_ROOT = buildRoot(CLOUD_EVENTS_HOST);

export const CLOUD_FUNCTIONS_HOST =
  process.env["CLOUD_FUNCTIONS_HOST"] ?? buildServiceUrl("functions.cloud.us" as ServiceName, "2021-11-16", isProd());
export const CLOUD_FUNCTIONS_ROOT = buildRoot(CLOUD_FUNCTIONS_HOST);

export const CLOUD_LOGS_HOST =
  process.env["CLOUD_LOGS_HOST"] ?? buildServiceUrl("logs.cloud" as ServiceName, "2021-11-16", isProd());
export const CLOUD_LOGS_ROOT = buildRoot(CLOUD_LOGS_HOST);

export const CLOUD_STORAGE_HOST =
  process.env["CLOUD_STORAGE_HOST"] ?? buildServiceUrl("storage.cloud" as ServiceName, "2021-11-16", isProd());
export const CLOUD_STORAGE_ROOT = buildRoot(CLOUD_STORAGE_HOST);

export const CLOUD_TRANSPORTS_HOST =
  process.env["CLOUD_TRANSPORTS_HOST"] ?? buildServiceUrl("transports.cloud" as ServiceName, "2021-11-16", isProd());
export const CLOUD_TRANSPORTS_ROOT = buildRoot(CLOUD_TRANSPORTS_HOST);

export const CLOUD_WORKFLOWS_HOST =
  process.env["CLOUD_WORKFLOWS_HOST"] ?? buildServiceUrl("workflows.cloud" as ServiceName, "2021-11-16", isProd());
export const CLOUD_WORKFLOWS_ROOT = buildRoot(CLOUD_WORKFLOWS_HOST);

// Billing Team
export const BILLING_HOST =
  process.env["BILLING_HOST"] ?? `https://api.billing.${isProd() ? "" : "preproduction."}stedi.com/`;

// Ledger Team
export const LEDGER2_HOST = process.env["LEDGER2_HOST"] ?? buildServiceUrl("ledger", "2021-06-01", isProd());
